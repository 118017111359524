import { CourseType } from '@/controllers/graphql/generated';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { I18N_CODES } from '@/lib/constants/general';
import { ROUTES } from '@/controllers/router/router.contants';
import { useTranslation } from '@/middleware/i18n';
import {
  CourseLandingPageModuleHelper,
} from '@/components/courseLanding/CourseLandingPageModule/CourseLandingPageModule.helpers/CourseLandingPageModuleHelper';

interface UseMergedLandingCTA {
  (options: {
    courseType: CourseType;
    courseSlug: string;
    postpaidCourseSlug?: string;
    prepaidCourseSlug?: string;
    formIdModifier: string;
    source?: string;
  }) : {
    text: string;
    ctaHref: string;
  };
}

export const useMergedLandingCTA: UseMergedLandingCTA = (options) => {
  const {
    courseType,
    courseSlug,
    source,
    postpaidCourseSlug,
    prepaidCourseSlug,
    formIdModifier,
  } = options;

  const { t } = useTranslation([I18N_CODES.common, I18N_CODES.cta]);

  const [authUser] = useAuthUser({ ssr: false });

  const mergedSurveyOptions = {
    courseSlug,
    source,
    form_id_modifier: formIdModifier,
    postpaidCourseSlug,
    prepaidCourseSlug,
  };

  if (!authUser) {
    return {
      text: t(`${I18N_CODES.cta}:profession_cover_cta_button`),
      ctaHref: courseType === CourseType.Postpaid
        ? ROUTES.application.signUpSurvey({
          ...mergedSurveyOptions,
        })
        : ROUTES.consultation.signUpSurvey({
          ...mergedSurveyOptions,
        }),
    };
  }

  const queryParams = CourseLandingPageModuleHelper.getQueryParamsString({
    courseSlug,
    source,
  });

  return {
    text: t(`${I18N_CODES.cta}:profession_cover_cta_button`),
    ctaHref: courseType === CourseType.Postpaid
      ? `${ROUTES.learn.newFtApplication}?${queryParams}`
      : `${ROUTES.consultation.index}?${queryParams}`,
  };
};
